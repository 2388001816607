import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { GenericFilterComponent } from '../../components/generic-filter/generic-filter.component';
import { FormControlZoneComponent } from '../form-control-zone/form-control-zone.component';
import { FilterInput, FilterOutput } from 'src/app/features/recherche/services/recherche-service/recherche.service';
import { TypeEnum as TypeActivite } from '@metarisc/metarisc-js/lib/model/ActiviteErp';
import { TypeEnum as TypeDossier } from '@metarisc/metarisc-js/lib/model/Dossier';
import { SearchInFilterControlValueAccessor } from '../../components/search-in-filter/search-in-filter.component';

type fnOnChange = (_: { [key: string]: string | string[] | null } | undefined) => void;

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgTemplateOutlet,
    GenericFilterComponent,
    FormControlZoneComponent,
    SearchInFilterControlValueAccessor,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FiltersControlValueAccessor,
    },
  ],
})
export class FiltersControlValueAccessor implements ControlValueAccessor {
  @Input() filtersToDisplay: FilterInput[] = [];

  form = this.fb.group({
    //multiverse
    trierPar: [],
    geojson: [],
    type: [],
    pei: [],
    //erp
    avis_exploitation: [],
    type_activite: [],
    presence_locaux_sommeil: [],
    references_exterieures: [],
    //pei
    numero: [],
    statut: [],
    domanialite: [],
    est_disponible: [],
    //tournées
    periode: [],
    //dossiers
    type_dossier: [],
    workflow_actif: [],
    affecte: [],
    erp: [],
    //support règlementaire
    contenu: [],
  });

  activityTypes = Object.values(TypeActivite);
  //TODO A revoir avec les valeurs du sdk
  commissionTypes = [
    'Sous-commission départementale',
    'Commission communale',
    'Commission intercommunale',
    "Commission d'arrondissement",
    'CoDERST',
  ];
  typesDossier = Object.values(TypeDossier);
  workflowsActif = new Map<string, string>([
    ['analyse_de_risque', 'Analyse de risque'],
    ['validation', 'Validation'],
    ['arrivee_sis', 'Arrivée SIS'],
    ['arrivee_sis_prev', 'Arrivée SIS Prévention'],
    ['arrivee_secretariat_commission', 'Arrivée Secrétariat Commission'],
    ['consultation_sis', 'Consultation SIS'],
    ['passage_commission', 'Passage en Commission'],
    ['relecture', 'Relecture'],
    ['visite', 'Visite'],
    ['arrivee_secretariat', 'Arrivée Secrétariat'],
    ['workflow', 'Workflow'],
  ]);
  result?: { [key: string]: string | string[] | null };

  touched = false;
  disabled = false;

  constructor(private fb: FormBuilder) {}

  onChange: fnOnChange = () => {
    // do nothing
  };
  onTouch: () => void = () => {
    // do nothing
  };

  getMap(controlNameList: FilterOutput[]): Map<FilterOutput, FormControl> {
    const res = new Map<FilterOutput, FormControl>();
    for (const controlName of controlNameList) {
      res.set(controlName, this.form.controls[controlName] as FormControl);
    }
    return res;
  }
  polygonConfirm(): void {
    this.applyFilter(this.getMap(this.filtersToDisplay as FilterOutput[]));
  }

  applyFilter(event: Map<string, FormControl>): void {
    this.markAsTouched();
    event.forEach((control: FormControl, name: string) => {
      this.form.get(name)?.patchValue(control.value);
    });
    this.result = { ...this.form.value };
    this.onChange(this.result);
  }

  displayFilter(filter: FilterInput): boolean {
    return this.filtersToDisplay.includes(filter);
  }

  writeValue(filters: { [key: string]: string | string[] }): void {
    if (filters) {
      this.form.patchValue(filters);
    } else {
      this.form.reset();
    }
    this.result = filters;
  }

  registerOnChange(fn: fnOnChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouch();
      this.touched = true;
    }
  }

  getReferences(): string[] {
    // console.log(this.form.get('references_exterieures')?.value);
    return this.form.get('references_exterieures')?.value || [];
  }
}
