<app-generic-search-input
  #searchInput
  [endpoint]="endpoint"
  [search_method]="search_method"
  (pressKeyAction)="search($event)"
  (pressEnterAction)="search($event)"
  [loadInit]="false"
  [search_value]="value"
></app-generic-search-input>
<ul class="list-group overflow-y-auto mt-1" style="max-height: 300px">
  @for (item of list; track item.id) {
    <li (click)="submit(item)" class="cursor-pointer list-group-item">
      {{ loadProperty(item) }}
    </li>
  }
  @if (list?.length === 0) {
    <span class="ff-light text-info p-2">Aucun résultat</span>
  }
</ul>
