import { inject, Injectable, signal } from '@angular/core';
import { Dossier } from '@metarisc/metarisc-js/lib/model/Dossier';
import { MetariscService } from 'src/app/services/metarisc-service/metarisc.service';

@Injectable({
  providedIn: 'root',
})
export class FicheDossierService {
  dossier = signal<(Dossier & { est_archive: boolean }) | undefined>(undefined);
  private metaService = inject(MetariscService);

  sectionElement?: HTMLElement;

  setDossier(dossier: Dossier & { est_archive: boolean }): void {
    this.dossier.set(dossier);
  }
  setDossierFromApi(id: string): Promise<unknown> | undefined {
    return this.metaService
      .getMetarisc()
      .dossiers?.getDossier(id)
      .then((response) => {
        this.setDossier(response.data as Dossier & { est_archive: boolean });
      });
  }
}
