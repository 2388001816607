import { Component, Input } from '@angular/core';
import { GenericSearchInputComponent } from '../../forms/generic-search-input/generic-search-input.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Collection } from '@metarisc/metarisc-js';

@Component({
  selector: 'app-search-in-filter',
  standalone: true,
  imports: [GenericSearchInputComponent],
  templateUrl: './search-in-filter.component.html',
  styleUrls: ['./search-in-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SearchInFilterControlValueAccessor,
    },
  ],
})
export class SearchInFilterControlValueAccessor implements ControlValueAccessor {
  id: string = '';
  list?: any[];
  @Input({ required: true }) propertyForView!: string[];
  @Input({ required: true }) search_method!: string;
  @Input({ required: true }) endpoint!: string;
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(id: string): void {
    this.id = id;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  search(collection: Collection<any>): void {
    collection.autoPagingIteratorToArray(10).then((list) => {
      this.list = list;
    });
  }
  value: string = '';
  submit(item: any): void {
    this.value = this.loadProperty(item);
    this.list = undefined;
    this.id = item.id;
    this.onChange(this.id);
  }

  loadProperty(item: any): string {
    return this.propertyForView
      .map((property) => {
        return property.split('.').reduce((prev, curr) => prev && prev[curr], item);
      })
      .join(' ');
  }
}
