import { Component, Input, inject, HostListener, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Enveloppe } from 'src/app/models/dossier.model';
import { MetariscService } from 'src/app/services/metarisc-service/metarisc.service';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { BadgeDossierTypeComponent } from 'src/app/shared/components/badges/badge-dossier-type/badge-dossier-type.component';
import { RouterModule, Router } from '@angular/router';
import { FicheDossierService } from 'src/app/features/dossier/services/fiche-dossier/fiche-dossier.service';

interface DossierEnveloppe {
  id: string;
  numero: string;
  objet: string;
  type: string;
}

@Component({
  selector: 'app-badge-enveloppe',
  standalone: true,
  imports: [CommonModule, LoadingComponent, BadgeDossierTypeComponent, RouterModule],
  templateUrl: './badge-enveloppe.component.html',
  styleUrls: ['./badge-enveloppe.component.scss'],
})
export class BadgeEnveloppeComponent {
  @Input() enveloppe!: Enveloppe;
  @HostBinding('class.active') get isActive(): boolean {
    return this.isPopinOpen;
  }

  private metariscService = inject(MetariscService);
  private router = inject(Router);
  private dossierService = inject(FicheDossierService);

  isPopinOpen = false;
  loading: Promise<unknown> | undefined;
  dossiers: DossierEnveloppe[] = [];

  constructor() {}

  onShowEnveloppe(event: Event): void {
    const target = event.target as HTMLElement;
    const routerLink = target.closest('[routerLink]');

    if (routerLink) {
      // Si on clique sur un routerLink, on ne fait rien et on laisse la navigation se faire
      return;
    }

    // Sinon on gère l'ouverture/fermeture de la popin
    event.preventDefault();
    event.stopPropagation();
    this.isPopinOpen = !this.isPopinOpen;

    if (this.isPopinOpen) {
      this.loadDossiers();
    }
  }

  private loadDossiers(): void {
    this.loading = this.metariscService
      .getMetarisc()
      .request({
        method: 'GET',
        endpoint: `/dossiers?enveloppe=${this.enveloppe.id}`,
      })
      .then((response) => {
        this.dossiers = (response?.data as unknown as { data: DossierEnveloppe[] }).data;
      });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.closest('app-badge-enveloppe')) {
      this.isPopinOpen = false;
    }
  }

  navigateToDossier(dossierId: string, event: Event): void {
    event.stopPropagation();
    this.isPopinOpen = false;
  }
}
