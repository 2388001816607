import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge-categorie-erp',
  templateUrl: './badge-categorie-erp.component.html',
  styleUrls: ['./badge-categorie-erp.component.scss'],
  standalone: true,
  imports: [],
})
export class BadgeCategorieErpComponent {
  @Input() data!: string;
}
