import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UniversalFormDualSelectComponent } from '../universal-form-dual-select/universal-form-dual-select.component';

@Component({
  selector: 'app-universal-form-dual-select-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, UniversalFormDualSelectComponent],
  templateUrl: './universal-form-dual-select-page.component.html',
  styleUrls: ['./universal-form-dual-select-page.component.scss'],
})
export class UniversalFormDualSelectPageComponent implements OnInit {
  form: FormGroup;
  testOptions: string[] = ['A - 1', 'A - 2', 'A - 3', 'B - 1', 'B - 2', 'C - 1', 'C - 2', 'C - 3', 'C - 4', 'D - 1'];

  customLabels = {
    firstLabel: 'Type',
    secondLabel: 'Catégorie',
  };

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      dualSelect: [''],
    });
  }

  ngOnInit(): void {
    // Écouter les changements de valeur
    this.form.get('dualSelect')?.valueChanges.subscribe((value) => {
      console.log('Valeur sélectionnée:', value);
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      console.log('Formulaire soumis avec la valeur:', this.form.value);
    } else {
      console.log('Formulaire invalide');
    }
  }

  setValue(): void {
    this.form.patchValue({
      dualSelect: 'B - 2',
    });
  }

  reset(): void {
    this.form.reset();
  }
}
