<div class="badge-enveloppe" (click)="onShowEnveloppe($event)">
  <div class="envelope-icon">
    <div class="envelope-flap"></div>
    <div class="envelope-body"></div>
  </div>
  <div class="badge-content">
    <span class="badge-label">{{ enveloppe.titre }}</span>
    @if (enveloppe.nb_dossiers !== undefined) {
      <span class="badge-count">({{ enveloppe.nb_dossiers }})</span>
    }
  </div>
</div>

@if (isPopinOpen) {
  <div class="dossiers-popin">
    <div class="popin-header">
      <button class="btn-close" (click)="isPopinOpen = false">
        <i class="bi bi-x"></i>
      </button>
    </div>

    <app-loading [load]="loading">
      @if (dossiers.length > 0) {
        <div class="dossiers-list">
          @for (dossier of dossiers; track dossier.id) {
            <a
              class="dossier-item inherit"
              [routerLink]="['/dossier', dossier.id, 'workflows']"
              (click)="navigateToDossier(dossier.id, $event)"
            >
              <div class="dossier-objet mb-1">{{ dossier.objet }}</div>
              <app-badge-dossier-type [type]="dossier.type"></app-badge-dossier-type>
            </a>
          }
        </div>
      } @else {
        <div class="empty-state">Aucun dossier dans cette enveloppe</div>
      }
    </app-loading>
  </div>
}
