<div class="badge-content d-flex">
  @for (erp of list; track erp.id; let id = $index; let isFirst = $first; let isLast = $last) {
    <ng-container>
      <div
        data-bs-toggle="collapse"
        attr.href="#{{ ids[id] }}"
        role="button"
        aria-expanded="false"
        attr.aria-controls="{{ ids[id] }}"
        class="polygon-bloc type d-flex icon-badge align-items-center gap-1"
        [ngClass]="{ first: isFirst, last: isLast }"
      >
        <div class="svg-container">
          <app-svg-icon class="svg-icon" [path]="getIconPath(erp.type)"></app-svg-icon>
        </div>

        {{ erp.type || 'ERP' }}
      </div>

      @if (!isLast) {
        <div class="collapse collapse-horizontal d-flex align-items-center" id="{{ ids[id] }}">
          <div class="polygon-bloc name">{{ erp.name || 'ERP sans nom' }}</div>
        </div>
      } @else {
        <a
          class="polygon-bloc name last d-flex align-items-center"
          [ngClass]="{ 'cursor-pointer': clickable }"
          [routerLink]="clickable ? '/erp/fiche-erp/' + erp.id : null"
          (click)="clickable && $event.stopPropagation()"
        >
          {{ erp.name || 'ERP sans nom' }}
        </a>
      }
    </ng-container>
  }
</div>
