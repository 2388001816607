  @if (displayLabel) {
    <div class="col-sm-12 dual-select-title mb-1">
      <label class="form-label">{{ getPrettyText(field?.label ?? '') }}</label>
    </div>
  }
  <div class="row mb-2 flex-grow-1" [formGroup]="form">
    <div class="col-sm-6">
      <div class="dual-select-container">
        <label class="form-label" for="first-value-select">
          {{ labels?.firstLabel ?? 'Première valeur' }}
          @if (field?.required) {
            <span class="required-field">*</span>
          }
        </label>
        <select id="first-value-select" class="form-select" formControlName="firstValue">
          <option value="">Sélectionnez une valeur</option>
          @for (value of firstValues; track value) {
            <option [value]="value">{{ value }}</option>
          }
        </select>
        @if (form.get('firstValue')?.invalid && form.get('firstValue')?.touched) {
          <div class="error-message">Ce champ est requis</div>
        }
      </div>
    </div>
    <div class="col-sm-6">
      <div class="dual-select-container">
        <label class="form-label" for="second-value-select">
          {{ labels?.secondLabel ?? 'Deuxième valeur' }}
          @if (field?.required) {
            <span class="required-field">*</span>
          }
        </label>
        <select
          id="second-value-select"
          class="form-select"
          formControlName="secondValue"
          [disabled]="!form.get('firstValue')?.value"
        >
          <option value="">Sélectionnez une valeur</option>
          @for (value of currentValues; track value) {
            <option [value]="value">{{ value }}</option>
          }
        </select>
        @if (form.get('secondValue')?.invalid && form.get('secondValue')?.touched) {
          <div class="error-message">Ce champ est requis</div>
        }
      </div>
    </div>
  </div>
