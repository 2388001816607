import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-badge-dossier-type',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './badge-dossier-type.component.html',
  styleUrls: ['./badge-dossier-type.component.scss'],
})
export class BadgeDossierTypeComponent {
  @Input() type: string = '';

  formatType(type: string): string {
    // Si le type est vide ou undefined, retourner une chaîne vide
    if (!type) return '';

    // Séparer le type si il contient un ":"
    const parts = type.split(':');
    const baseType = parts.length > 1 ? parts[1] : type;

    // Remplacer les underscores par des espaces et mettre en forme
    return baseType
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
}
