import { Component, forwardRef, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlInfo, UniversalFormService } from 'src/app/services/universal-form/universal-form.service';

@Component({
  selector: 'app-universal-form-dual-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './universal-form-dual-select.component.html',
  styleUrls: ['./universal-form-dual-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UniversalFormDualSelectComponent),
      multi: true,
    },
  ],
})
export class UniversalFormDualSelectComponent implements OnInit, ControlValueAccessor {
  @Input() options: string[] = [];
  @Input() labels:
    | {
        firstLabel: string;
        secondLabel: string;
      }
    | undefined;
  @Input() field: ControlInfo | undefined;
  @Input() displayLabel: boolean = true;

  universalFormService = inject(UniversalFormService);

  form: FormGroup;
  firstValues: string[] = [];
  secondValues: { [key: string]: string[] } = {};
  currentValues: string[] = [];

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      firstValue: [''],
      secondValue: [''],
    });
  }

  ngOnInit(): void {
    this.processOptions();

    // Réagir aux changements de la première valeur sélectionnée
    this.form.get('firstValue')?.valueChanges.subscribe((firstValue: string) => {
      this.currentValues = this.secondValues[firstValue] || [];
      this.form.patchValue({ secondValue: '' }, { emitEvent: false });
      this.updateValue();
    });

    // Réagir aux changements de la valeur sélectionnée
    this.form.get('secondValue')?.valueChanges.subscribe(() => {
      this.updateValue();
    });
  }

  private processOptions(): void {
    const valuesMap = new Map<string, Set<string>>();
    try {
      this.options.forEach((option) => {
        const [letter, value] = option.split(' - ');
        if (!valuesMap.has(letter)) {
          valuesMap.set(letter, new Set());
        }
        valuesMap.get(letter)?.add(value);
      });
      this.firstValues = Array.from(valuesMap.keys()).sort();
      this.secondValues = {};

      valuesMap.forEach((firstValues, secondValue) => {
        this.secondValues[secondValue] = Array.from(firstValues).sort();
      });
    } catch (error) {
      console.error("Erreur lors du traitement des options, le format ne correspond pas à 'A – B'", error);
    }
  }

  private updateValue(): void {
    const firstValue = this.form.get('firstValue')?.value;
    const secondValue = this.form.get('secondValue')?.value;
    if (firstValue && secondValue) {
      this.onChange(`${firstValue} - ${secondValue}`);
    } else {
      this.onChange(null);
    }
    this.onTouched();
  }

  // Implémentation de ControlValueAccessor
  writeValue(value: string | null): void {
    if (value) {
      const [firstVal, secondVal] = value.split(' - ');

      // 1. Affecter d'abord la première valeur
      this.form.patchValue(
        {
          firstValue: firstVal,
        },
        { emitEvent: false },
      );

      // 2. Mettre à jour la liste des valeurs secondaires
      this.currentValues = this.secondValues[firstVal] || [];
      // 3. Affecter ensuite la valeur secondaire
      setTimeout(() => {
        this.form.patchValue(
          {
            secondValue: secondVal,
          },
          { emitEvent: false },
        );
      });
    } else {
      this.form.patchValue(
        {
          firstValue: '',
          secondValue: '',
        },
        { emitEvent: false },
      );
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  getPrettyText(text: string): string {
    if (!text) {
      return '';
    }
    // On ne garde que le dernier terme pour les clés composées (ex: commission.commission_date)
    text = text.substring(text.lastIndexOf('.') + 1);
    return this.universalFormService.getPrettyText(text);
  }
}
