<div class="container mt-4">
  <div class="row">
    <div class="col-12">
      <h2>Test du composant Universal Form Dual Select</h2>
      <hr>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Formulaire de test</h5>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <app-universal-form-dual-select
              [options]="testOptions"
              formControlName="dualSelect"
              [labels]="customLabels"
            ></app-universal-form-dual-select>

            <div class="d-flex gap-2">
              <button type="submit" class="btn btn-primary">Soumettre</button>
              <button type="button" class="btn btn-secondary" (click)="setValue()">
                Définir B–2
              </button>
              <button type="button" class="btn btn-danger" (click)="reset()">
                Réinitialiser
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">État du formulaire</h5>
          <pre>{{ form.value | json }}</pre>
          <hr>
          <h6>Options disponibles :</h6>
          <ul>
            @for (option of testOptions; track option) {
              <li>{{ option }}</li>
            }
          </ul>
        </div>
      </div>
    </div>
  </div>
</div> 