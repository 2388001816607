<div class="input-group">
  <div class="input-group-text border-end-0 px-2 fg-background">
    <i class="bi bi-search fs-5 text-secondary"></i>
  </div>
  <input
    #searchInput
    class="form-control border-start-0 border-end-0 py-1 fg-background"
    type="text"
    placeholder="Rechercher"
    [(ngModel)]="search_value"
    (keyup.enter)="searchOnEnterPress()"
    style="min-width: 100px"
  />
  <div class="input-group-text border-start-0 px-2 fg-background">
    <i class="bi bi-x-lg fs-5 cursor-pointer text-secondary" (click)="resetSearch()"></i>
  </div>
</div>
