import { NgClass, NgIf } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { DossierComplet, EtatDeci, EtatErp } from 'src/app/models/dossier.model';
import moment from 'moment';
import 'moment/locale/fr';
import { RouterLink } from '@angular/router';
import { TagComponent } from '../tag/tag.component';
import { ViaAppComponent } from '../via-app/via-app.component';
import { ERP } from '@metarisc/metarisc-js/lib/model/ERP';
import { PEI } from '@metarisc/metarisc-js/lib/model/PEI';
import { DossierErp } from '@metarisc/metarisc-js/lib/model/DossierErp';
import { DossierDeci } from '@metarisc/metarisc-js/lib/model/DossierDeci';
import { BadgeDeciTypeComponent } from '../badges/badge-deci-type/badge-deci-type.component';
import { BadgeErpComponent } from 'src/app/shared/components/badges/badge-erp/badge-erp.component';
import { NbToArrayPipe } from '../../pipes/nb-to-array.pipe';
import { BadgeDossierArchiveComponent } from '../badges/badge-dossier-archive/badge-dossier-archive.component';
import { BadgeEnveloppeComponent } from '../badges/badge-enveloppe/badge-enveloppe.component';
import { UniversalFormService } from 'src/app/services/universal-form/universal-form.service';

@Component({
  selector: 'app-recap-dossier',
  templateUrl: './recap-dossier.component.html',
  styleUrls: ['./recap-dossier.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    RouterLink,
    TagComponent,
    ViaAppComponent,
    BadgeDeciTypeComponent,
    BadgeErpComponent,
    NbToArrayPipe,
    BadgeDossierArchiveComponent,
    BadgeEnveloppeComponent,
  ],
})
export class RecapDossierComponent implements OnInit {
  @Input() placeholder?: number;
  @Input() dossier!: DossierComplet;
  erp?: ERP;
  pei?: PEI;
  private universalFormService = inject(UniversalFormService);

  ngOnInit(): void {
    if (this.placeholder) return;
    const erpTest = this.dossier.dossier as DossierErp;
    const deciTest = this.dossier.dossier as DossierDeci;
    if (erpTest.erp) {
      this.erp = erpTest.erp;
    }
    if (deciTest.pei) {
      this.pei = deciTest.pei;
    }
  }

  getEtatDeci(): EtatDeci {
    return EtatDeci.DOSSIER_OUVERT_APPLIQUE;
  }
  getEtatErp(): EtatErp {
    return EtatErp.VALIDE;
  }

  formatDate(): string {
    moment().locale('fr');
    const date = moment(this.dossier.dossier.date_de_creation);
    return date.fromNow();
  }

  getPrettyLabel(type: string): string {
    return this.universalFormService.getPrettyValue(type);
  }
}
