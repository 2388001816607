import { Routes } from '@angular/router';
import { ConnectedLayout } from './layouts/connected-layout/connected-layout.component';
import { AccessPage } from './pages/access-page/access-page.component';
import { CalendarPage } from './pages/calendar-page/calendar-page.component';
import { DashboardPage } from './pages/dashboard-page/dashboard-page.component';
import { FluxPage } from './pages/flux-page/flux-page.component';
import { MapPage } from './pages/map-page/map-page.component';
import { NotfoundPage } from './pages/notfound-page/notfound-page.component';
import { NotificationPage } from './pages/notification-page/notification-page.component';
import { RecentActivityLayout } from './layouts/recent-activity-layout/recent-activity-layout.component';
import { SuiviDesDossiersPage } from './pages/suivi-des-dossiers-page/suivi-des-dossiers-page.component';
import { SpecialEventDashboardPage } from './pages/special-event-dashboard-page/special-event-dashboard-page.component';
import { InvitationAwaitingValidationPage } from './pages/invitation-awaiting-validation-page/invitation-awaiting-validation-page.component';
import { ErrorPage } from './pages/error-page/error-page.component';
import { OrganizationChoicePage } from './pages/organization-choice-page/organization-choice-page.component';
import { authGuard } from './guards/auth.guard';
import { organizationGuard } from './guards/organization.guard';
import { UniversalFormLayoutPageComponent } from './shared/components/universal-form-layout-page/universal-form-layout-page.component';
import { UniversalFormDualSelectPageComponent } from './shared/components/universal-form-dual-select-page/universal-form-dual-select-page.component';

export const routes: Routes = [
  { path: '', redirectTo: '/accueil/flux', pathMatch: 'full', canActivate: [authGuard] },
  { path: 'access', component: AccessPage },
  { path: 'choix-organisation', component: OrganizationChoicePage, canActivate: [authGuard] },
  {
    path: '',
    component: ConnectedLayout,
    canActivateChild: [authGuard, organizationGuard],
    children: [
      { path: 'form', component: UniversalFormLayoutPageComponent },
      { path: 'form-dual-select', component: UniversalFormDualSelectPageComponent },
      {
        path: 'recherche',
        loadChildren: () => import('./features/recherche/recherche-routes').then((m) => m.rechercheRoutes),
      },
      { path: 'user', loadChildren: () => import('./features/user/user-routes').then((m) => m.userRoutes) },
      { path: 'deci', loadChildren: () => import('./features/deci/deci-routes').then((m) => m.deciRoutes) },
      { path: 'erp', loadChildren: () => import('./features/erp/erp-routes').then((m) => m.erpRoutes) },
      { path: 'dossier', loadChildren: () => import('./features/dossier/dossier-routes').then((m) => m.dossierRoutes) },
      {
        path: 'organisation',
        loadChildren: () => import('./features/organisation/organisation-routes').then((m) => m.organisationRoutes),
      },
      {
        path: 'accueil',
        component: RecentActivityLayout,
        children: [
          { path: '', redirectTo: 'flux', pathMatch: 'full' },
          { path: 'flux', component: FluxPage, title: "Flux d'activité" },
          { path: 'tableau-de-bord', component: DashboardPage, title: 'Tableau de bord' },
          { path: 'evenement-special/:id', component: SpecialEventDashboardPage },
        ],
      },
      { path: 'cartographie', component: MapPage, title: 'Explorer la carte' },
      { path: 'notifications', component: NotificationPage, title: 'Notification' },
      { path: 'suivi-des-dossiers', component: SuiviDesDossiersPage, title: 'Suivi des dossiers' },
      { path: 'calendrier', component: CalendarPage, title: 'Calendrier' },
    ],
  },
  { path: 'invitation-en-attente', component: InvitationAwaitingValidationPage },
  // Error route
  { path: 'erreur', component: ErrorPage },
  // Wildcard route
  { path: '**', component: NotfoundPage },
];
