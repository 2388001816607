<div class="d-flex vh-100 overflow-hidden">
  <div class="d-flex flex-column w-100">
    <div class="left_side d-flex flex-column p-4">
      <!-- Logo -->
      <a href="#" class="logo-bloc d-flex align-items-center text-white text-decoration-none mb-3">
        <img src="/assets/images/metadome.svg" height="36px" />
        <span class="ms-2">METARISC</span>
      </a>

      <hr class="sidebar-hr" />

      <!-- Barre d'accès rapide à la recherche -->
      <form
        [formGroup]="searchForm"
        (ngSubmit)="onSearch()"
        class="position-relative d-flex justify-content-center mb-2 mt-3"
      >
        <div class="input-group search">
          <input
            id="search-input"
            formControlName="rechercheInput"
            class="form-control search-input"
            type="search"
            placeholder="Rechercher"
            aria-label="Search"
            #input
          />
          <span class="input-group-append">
            <button type="button" class="search-button" (click)="onSearch()">
              <i class="bi bi-search"></i>
            </button>
          </span>
        </div>
      </form>

      <!-- Menu général -->
      <div class="overflow-auto flex-grow-1 left-scroll">
        <ul class="nav nav-top nav-pills flex-column no-wrap mb-3">
          <li class="nav-item">
            <a
              routerLink="/accueil"
              routerLinkActive="active-link"
              #ar="routerLinkActive"
              class="nav-link link-light"
              (mouseenter)="isHovered.ar = true"
              (mouseleave)="isHovered.ar = false"
            >
              <i
                class="bi"
                [ngClass]="{
                  'bi-house-door': !ar.isActive && !isHovered.ar,
                  'bi-house-door-fill': ar.isActive || isHovered.ar,
                }"
              ></i>
              Activité récente
            </a>
          </li>
          <li class="nav-item">
            <a
              routerLink="/suivi-des-dossiers"
              routerLinkActive="active-link"
              #sdd="routerLinkActive"
              class="nav-link link-light"
              (mouseenter)="isHovered.sdd = true"
              (mouseleave)="isHovered.sdd = false"
            >
              <i
                class="bi"
                [ngClass]="{
                  'bi-folder': !sdd.isActive && !isHovered.sdd,
                  'bi-folder-fill': sdd.isActive || isHovered.sdd,
                }"
              ></i>
              Suivi des dossiers
            </a>
          </li>
          <li class="nav-item">
            <a
              routerLink="/cartographie"
              routerLinkActive="active-link"
              #elc="routerLinkActive"
              class="nav-link link-light"
              (mouseenter)="isHovered.elc = true"
              (mouseleave)="isHovered.elc = false"
            >
              <i
                class="bi"
                [ngClass]="{ 'bi-map': !elc.isActive && !isHovered.elc, 'bi-map-fill': elc.isActive || isHovered.elc }"
              ></i>
              Explorer la carte
            </a>
          </li>
          <li class="nav-item">
            <a
              routerLink="/notifications"
              routerLinkActive="active-link"
              #notif="routerLinkActive"
              class="nav-link link-light"
              (mouseenter)="isHovered.notif = true"
              (mouseleave)="isHovered.notif = false"
            >
              <i
                class="bi"
                [ngClass]="{
                  'bi-bell': !notif.isActive && !isHovered.notif,
                  'bi-bell-fill': notif.isActive || isHovered.notif,
                }"
              ></i>
              <div class="d-flex align-items-center gap-1">
                Notifications
                <div *ngIf="unread_counter() > 0" class="counter fs-6">{{ unread_counter() }}</div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a
              routerLink="/calendrier"
              routerLinkActive="active-link"
              #cal="routerLinkActive"
              class="nav-link link-light"
              (mouseenter)="isHovered.cal = true"
              (mouseleave)="isHovered.cal = false"
            >
              <i
                class="bi"
                [ngClass]="{
                  'bi-calendar-week': !cal.isActive && !isHovered.cal,
                  'bi-calendar-week-fill': cal.isActive || isHovered.cal,
                }"
              ></i>
              Calendrier
            </a>
          </li>
        </ul>

        <hr class="sidebar-hr" />

        <!-- Menu des "univers" -->
        <ul class="nav nav-pills flex-column no-wrap mb-2 mt-3">
          <li *ngFor="let universe of universes()" class="univers">
            <a class="nav-link link-light mb-1" *ngIf="universe.items.length">
              <div class="d-flex align-items-center fs-5 gap-1_5">
                <div class="icon-box">
                  <img *ngIf="universe.icon" [src]="universe.icon" class="icon" />
                </div>
                <span>{{ universe.label }}</span>
                <i
                  id="arrow-toggle"
                  [ngClass]="{
                    arrowChange: getShow(universe.name),
                  }"
                  (click)="changeShow(universe)"
                  class="bi bi-caret-down-fill fs-6"
                ></i>
              </div>
            </a>
            <ul *ngIf="getShow(universe.name)" class="px-2 mb-1">
              <li *ngFor="let item of universe.items" class="list-unstyled list-item d-flex align-items-center">
                <div
                  *ngIf="item.checkable"
                  (click)="toggleLink(item)"
                  [ngClass]="{ 'bi-pin': !item.checked, 'bi-pin-fill': !item.checked !== true }"
                  class="bi spin-icon text-primary"
                ></div>
                <div *ngIf="!item.checkable" class="bi bi-caret-right-fill spin-icon text-primary"></div>
                <a [routerLink]="getUrlWithoutQueryParams(item.url)" [queryParams]="getQueryParams(item.url)">
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <!-- Menu de l'utilisateur connecté -->
      <div class="dropup mt-2">
        <button
          class="btn btn-primary dropdown-toggle w-100 deco-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <app-loading [load]="!user?.first_name" display="inline" size="small" color="light">
            {{ user?.first_name + ' ' + user?.last_name | truncateString: 24 }}
          </app-loading>
        </button>
        <ul class="dropdown-menu">
          <li *ngIf="organisation">
            <span class="dropdown-item-text">
              {{ organisation.organisation?.nom }} - {{ organisation.role | titlecase }}
              <i
                class="bi bi-arrow-left-right cursor-pointer"
                routerLink="choix-organisation"
                title="Changer d'organisation"
              ></i>
            </span>
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li><a routerLink="user/profil" class="dropdown-item">Profil</a></li>
          <li><a routerLink="user/parametres" class="dropdown-item">Paramètres</a></li>
          <li><hr class="dropdown-divider" /></li>
          <li><a routerLink="user/support" class="dropdown-item">Centre de support</a></li>
          <li>
            <a href="https://metarisc.fr" target="_blank" class="dropdown-item"
              ><i class="bi bi-box-arrow-up-right"></i> En savoir plus</a
            >
          </li>
          <li>
            <a class="dropdown-item" href="https://docs.metarisc.fr/" target="_blank"
              ><i class="bi bi-box-arrow-up-right"></i> Aide</a
            >
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li><a class="dropdown-item cursor-pointer" (click)="logout()">Déconnexion</a></li>
        </ul>
      </div>
    </div>
    <div *ngIf="environmentOptions.warningMessage" class="demo text-white p-2 text-center">
      {{ environmentOptions.warningMessage }}
    </div>
  </div>

  <div id="right-side" class="right_side bg-light p-4">
    <app-toast-notification></app-toast-notification>

    <!-- Contenu de la page -->
    <router-outlet></router-outlet>
  </div>
</div>
